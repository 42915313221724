import { forwardRef, type ElementRef } from 'react'
import { Breadcrumbs as ReactAriaBreadcrumbs, Breadcrumb, Link, type BreadcrumbsProps } from 'react-aria-components'
import { Icon } from '#app/components/media/icon'
import { cn } from '#app/utils/tailwind-merge.ts'

type Breadcrumb = {
	href: string
	label: string
}

type BreadcrumbItem = Breadcrumb & {
	id: string | number
}

const Breadcrumbs = forwardRef<ElementRef<typeof ReactAriaBreadcrumbs>, BreadcrumbsProps<BreadcrumbItem>>(({ className, ...props }, ref) => {
	return (
		<ReactAriaBreadcrumbs ref={ref} className={cn('breadcrumbs', className)} {...props}>
			{({ id, href, label }: BreadcrumbItem) => (
				<Breadcrumb key={id} className="breadcrumb">
					<Link className="peer" href={href}>
						{label}
					</Link>
					<Icon name="chevron-right" aria-hidden="true" className="icon peer-data-[current]:hidden" />
				</Breadcrumb>
			)}
		</ReactAriaBreadcrumbs>
	)
})
Breadcrumbs.displayName = 'Breadcrumbs'

export { Breadcrumbs, type Breadcrumb, type BreadcrumbItem }
