import { useMatches } from '@remix-run/react'
import { type ElementRef, forwardRef } from 'react'
import { type BreadcrumbsProps } from 'react-aria-components'
import { type BreadcrumbItem, Breadcrumbs } from './breadcrumbs.tsx'

const RemixBreadcrumbs = forwardRef<ElementRef<typeof Breadcrumbs>, BreadcrumbsProps<BreadcrumbItem>>(({ ...props }, ref) => {
	const matches = useMatches()
	const items: BreadcrumbItem[] = matches
		.filter(match => match.handle && (match.handle as { breadcrumb: string }).breadcrumb)
		.map(match => {
			const { id, pathname: href } = match
			const label = (match.handle as { breadcrumb: string }).breadcrumb
			return { id, href, label }
		})
	return <Breadcrumbs ref={ref} items={items} {...props} />
})
RemixBreadcrumbs.displayName = 'RemixBreadcrumbs'

export { RemixBreadcrumbs }
